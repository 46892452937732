import ResellerTable from './ResellerTable.vue';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'reseller Approved sender IDs',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  name: 'approvedReseller-sender_ids',
  components: {
    ResellerTable
  },
  data() {
    return {
      parent: {
        name: 'approved',
        storeDataName: 'resellerApprovedSenderIDs',
        from: 'approved',
        status: 1
      }
    };
  },
  methods: {},
  computed: {},
  mounted() {}
};